var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "team-list" },
      [
        _c("div", { staticClass: "team-list-header" }, [
          _c(
            "div",
            { staticClass: "add-team-btn", on: { click: _vm.handleAddTeam } },
            [_c("span", [_vm._v("添加团队")])]
          ),
        ]),
        _c("add-team-dialog", {
          ref: "addTeamDialog",
          attrs: { team: _vm.team, teamListArr: _vm.teamListArr },
          on: { "refresh-team": _vm.refreshTeam },
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticClass: "team-list-content",
          },
          [
            _vm.teamTableList.length > 0
              ? _c(
                  "div",
                  { staticClass: "list-content-wrap" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.teamTableList },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "teamName",
                            label: "团队",
                            width: "340",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "creatorName",
                            label: "创建者",
                            width: "340",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "created",
                            formatter: _vm.dateFormatter,
                            label: "创建时间",
                            width: "150",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "address",
                            label: "操作",
                            width: "300",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "item-option" }, [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleOperateTeam(
                                                scope.row,
                                                "addSubTeam"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("添加子团队")]
                                      ),
                                      _c("span", { staticClass: "line" }, [
                                        _vm._v("|"),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleOperateTeam(
                                                scope.row,
                                                "editTeam"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("编辑团队")]
                                      ),
                                      _c("span", { staticClass: "line" }, [
                                        _vm._v("|"),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { color: "#FC7859" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleOperateTeam(
                                                scope.row,
                                                "deleteTeam"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2727085242
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm.teamTableList.length > 0
                      ? _c(
                          "el-pagination",
                          {
                            attrs: {
                              "current-page": _vm.pager.current,
                              "page-sizes": [10, 20, 50],
                              "page-size": _vm.pager.size,
                              layout: "total, sizes, prev, pager, next, slot",
                              total: _vm.teamsNumber,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                            },
                          },
                          [
                            _c("span", { staticClass: "pagination-text" }, [
                              _c(
                                "span",
                                [
                                  _vm._v("前往"),
                                  _c("el-input", {
                                    attrs: {
                                      oninput:
                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handlePagerJump(
                                          _vm.pagerJump
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.pagerJump,
                                      callback: function ($$v) {
                                        _vm.pagerJump = $$v
                                      },
                                      expression: "pagerJump",
                                    },
                                  }),
                                  _vm._v("页"),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePagerJump(_vm.pagerJump)
                                    },
                                  },
                                },
                                [_vm._v("跳转")]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _c("div", { staticClass: "team-list-empty" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "empty-tip empty-item" }, [
                    _c("span", [_vm._v("暂无团队信息")]),
                    _c("br"),
                    _c("span", [
                      _vm._v("为了更好的管理用户权限，请先添加团队哦～"),
                    ]),
                    _c("br"),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "empty-tip-btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleAddTeam },
                          },
                          [
                            _vm._v(
                              "\n                            添加团队\n                        "
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "empty-tip-btn add-children-team",
                            attrs: { type: "primary" },
                            on: { click: _vm.handleAddCurTeam },
                          },
                          [
                            _vm._v(
                              "\n                            添加当前子团队\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "empty-img empty-item" }, [
      _c("img", {
        attrs: { src: require("@src/assets/images/icon/team-empty-img.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }