<template>
    <div>
        <div class="team-list">
            <div class="team-list-header">
                <div class="add-team-btn" @click="handleAddTeam">
                    <span>添加团队</span>
                </div>
            </div>

            <!-- 添加团队 -->
            <add-team-dialog ref="addTeamDialog" :team="team" :teamListArr="teamListArr" @refresh-team="refreshTeam"></add-team-dialog>

            <div class="team-list-content" v-loading="listLoading">
                <div v-if="teamTableList.length > 0" class="list-content-wrap">
                    <el-table
                        :data="teamTableList"
                        style="width: 100%">
                        <el-table-column
                            prop="teamName"
                            label="团队"
                            width="340"
                            :show-overflow-tooltip='true'
                        >
                        </el-table-column>
                        <el-table-column
                            prop="creatorName"
                            label="创建者"
                            width="340"
                            :show-overflow-tooltip='true'
                        >
                        </el-table-column>
                        <el-table-column
                            prop="created"
                            :formatter="dateFormatter"
                            label="创建时间"
                            width="150"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="操作"
                            width="300"
                        >
                            <template slot-scope="scope">
                                <div class="item-option">
                                    <span @click="handleOperateTeam(scope.row, 'addSubTeam')">添加子团队</span>
                                    <span class="line">|</span>

                                    <span @click="handleOperateTeam(scope.row, 'editTeam')">编辑团队</span>
                                    <span class="line">|</span>

                                    <span @click="handleOperateTeam(scope.row, 'deleteTeam')" style="color:#FC7859">删除</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination
                        :current-page="pager.current"
                        :page-sizes="[10, 20, 50]"
                        :page-size="pager.size"
                        layout="total, sizes, prev, pager, next, slot"
                        :total="teamsNumber"
                        v-if="teamTableList.length > 0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                        <span class="pagination-text">
                            <span>前往<el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="pagerJump" @keyup.enter.native="handlePagerJump(pagerJump)"></el-input>页</span>
                            <span @click="handlePagerJump(pagerJump)">跳转</span>
                        </span>
                    </el-pagination>
                </div>
                <div v-else class="team-list-empty">
                    <div class="empty-img empty-item">
                        <img src="@src/assets/images/icon/team-empty-img.png" />
                    </div>
                    <div class="empty-tip empty-item">
                        <span>暂无团队信息</span><br/>
                        <span>为了更好的管理用户权限，请先添加团队哦～</span><br/>
                        <div>
                            <el-button class="empty-tip-btn" type="primary" @click="handleAddTeam">
                                添加团队
                            </el-button>
                            <el-button class="empty-tip-btn add-children-team" type="primary" @click="handleAddCurTeam">
                                添加当前子团队
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { team as teamUrl } from '#/js/config/api.json';
    import AddTeamDialog from './add-team-dialog.vue';

    export default {
        name: 'TeamList',
        props: {
            team: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            teamList: {
                type: Array,
            },
        },
        watch: {
            team(val) {
                // console.log(val);
                if(val && val.teamId != undefined) {
                    this.pager.current = 1;
                    // this.pager.size = 10;
                    this.pagerJump = 0;
                    this.getTeamList();
                    this.scrollTop();
                }
            },
            teamList(val) {
                // if(val[0].childTeams) {
                //     this.teamListArr = val[0].childTeams;
                // }
                this.teamListArr = val;
                // console.log(this.teamListArr);
            }
        },
        components: {
            AddTeamDialog
        },
        data() {
            return {
                pager: {
                    current: 1,
                    size: 10,
                },
                pagerJump: 0,
                teamListArr: [],

                teamsNumber: 0,
                listLoading: false,
                teamTableList: []
            }
        },
        mounted() {
            if(this.team && this.team.teamId) {
                this.getTeamList();
            }
            if(this.teamList.length > 0) {
                this.teamListArr = this.teamList;
            }
        },
        methods: {
            dateFormatter(row, column, cellValue, index) {
                return cellValue.split(' ')[0];
            },
            // 在底部时，点击翻页要定位到顶部
            scrollTop() {
                document.getElementsByClassName('member-page')[0].scrollTop = 0;
            },
            handleSizeChange(val){
                this.scrollTop();
                this.pager.size = val;
                this.pager.current = 1;
                this.getTeamList();
            },
            handleCurrentChange(current) {
                this.scrollTop();
                this.pager.current = current;
                this.getTeamList();
            },
            handlePagerJump(pagerJump) {
                pagerJump = Number.parseInt(pagerJump);
                this.pagerJump = pagerJump;
                if (
                    pagerJump > 0 &&
                    pagerJump <= Math.ceil(this.teamsNumber / this.pager.size)
                ) {
                    this.handleCurrentChange(pagerJump);
                }
            },

            refreshTeam(currentTeam) {
                // console.log('需要刷新列表-----------');
                this.$emit('refresh-team-list', currentTeam);
                //刷新团队列表
                this.getTeamList();
            },

            // 获取团队列表（下一级的团队列表）
            getTeamList() {
                this.listLoading = true;
                _request({
                    url: teamUrl.get_child_teams,
                    method: 'POST',
                    baseURL: "LbdOpenApi",
                    data: {
                        start: (this.pager.current - 1) * this.pager.size,
                        take: this.pager.size,
                        parentId: this.team.teamId
                    }
                }).then(res => {
                    this.teamsNumber = res.total;
                    this.teamTableList = res.list || [];
                }).finally(() => {
                    this.listLoading = false;
                })
            },

            handleAddTeam() {
                // console.log('点击添加团队按钮');
                this.$refs.addTeamDialog.show();
            },
            handleAddCurTeam() {
                // console.log('点击添加当前子团队按钮');
                this.handleOperateTeam(this.team, 'addSubTeam');
            },

            handleOperateTeam(curTeam,type){
                // console.log(curTeam);
                // console.log(type);
                if(type == 'addSubTeam') {
                    // console.log('添加子团队');
                    this.$refs.addTeamDialog.show(curTeam,type);
                } else if(type == 'editTeam') {
                    // console.log('编辑团队');
                    this.$refs.addTeamDialog.show(curTeam,type);
                } else if(type == 'deleteTeam') {
                    // console.log('删除该团队');
                    this.$refs.addTeamDialog.show(curTeam,type);
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .team-list {

        &-header {
            margin-bottom: 20px;
            .add-team-btn {
                cursor: pointer;
                width: 80px;
                height: 36px;
                line-height: 36px;
                border-radius: 4px;
                background-color: #38bc9c;
                color: #fff;
                text-align: center;
                font-size: 14px;
            }
        }

        &-content {
            .list-content-wrap {
                /deep/ .el-table {
                    color: #666;
                    .el-table__row td {
                        border-top: none;
                    }
                    th.is-leaf.el-table__cell {
                        background-color: #E8E8E8;
                        color: #666666;
                        border-right: 1px solid #CCCCCC;
                        // padding-left: 16px;
                        padding-left: 40px;
                        padding-right: 16px;
                    }
                    th.is-leaf.el-table__cell:nth-last-child(2) {
                        border-right: none;
                    }
                    tbody .el-table__cell {
                        border-right: 1px solid #ebeef5;
                        // padding-left: 16px;
                        padding-left: 40px;
                        padding-right: 16px;
                    }
                    .el-table__row td.el-table__cell:last-child {
                        border-right: none;
                    }
                }

                .item-common {
                    display: inline-block;
                }
                .item-avatar {
                    width: 30px;
                    height: 30px;
                }
                .item-info {
                    margin-left: 20px;
                    font-size: 14px;
                    color: #333333;
                    .item-info-name {
                        display: inline-block;
                        max-width: 200px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        vertical-align: top;
                    }
                    .item-info-title {
                        padding: 2px 7px;
                        margin-left: 26px;
                        background-color: #EAF8F5;
                        border-radius: 8px;
                        color: #38BC9D;
                        font-size: 12px;
                    }
                }
                .item-option {
                    span {
                        cursor: pointer;
                        display: inline-block;
                        margin-right: 10px;
                        color: #38BC9D;
                        font-size: 14px;
                    }
                    span.line {
                        cursor: text;
                        color: #CCCCCC;
                    }
                }
            }

            /deep/ .el-pagination {
                margin: 30px 0 40px;
                padding: 0;
                font-weight: normal;
                text-align: right;
                
                // 给分页的当前页设置边框
                .btn-next, .btn-prev {
                    height: 28px;
                    border: 1px solid #DDDDDD;
                }
                .btn-prev {
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                }
                .btn-next {
                    border-left: none;
                    border-radius: 0 4px 4px 0;
                }
                .el-pager {
                    border-top: 1px solid #DDDDDD;
                    border-bottom: 1px solid #DDDDDD;
                    li {
                        min-width: 26px;
                        height: 26px;
                        line-height: 26px;
                        font-size: 14px;
                    }
                }

                .el-pagination__total {
                    color: #666666;
                }
                .pagination-text{
                    color: #666666;
                    display: inline-block;
                    margin-left: 28px;
                    span{
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        .el-input{
                            width: 48px;
                            margin: 0 5px;
                            &__inner{
                                height: 28px;
                                line-height: 28px;
                                padding: 0 5px;
                            }
                        }
                        &:nth-of-type(2){
                            margin-left: 5px;
                            // color: $primary;
                            cursor: pointer;
                        }
                    }
                }
                // .el-pagination__total,
                // .el-pagination__sizes,
                // .pagination-text {
                //     float: right;
                // }
            }

            .team-list-empty {
                margin-top: 125px;
                margin-left: 80px;

                .empty-item {
                    display: inline-block;
                    vertical-align: top;
                    line-height: 2;
                }

                .empty-img {
                    margin-right: 80px;
                    width: 240px;
                }

                .empty-tip {
                    padding-top: 20px;

                    &-btn {
                        margin-top: 20px;
                        width: 100px;
                        height: 36px;
                        line-height: 36px;
                        padding: 0;
                        border-radius: 2px;
                        position: relative;
                        padding-left: 15px;

                        &::before {
                            content: ' ';
                            position: absolute;
                            top: 0;
                            left: 8px;
                            bottom: 0;
                            width: 14px;
                            height: 14px;
                            margin: auto;
                            background: url('~@src/assets/images/icon/icon-set.png') no-repeat;
                        }
                    }
                    .add-children-team{
                        margin-left: 10px;
                        width: 140px;

                        &:before{
                            background-image: url("~@src/assets/images/customer/ic_add.png") !important;
                        }
                    }
                }
            }
        }
    }
</style>
